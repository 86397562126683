import { ref } from 'vue'

import api from '@/api'

const useActionsReport = () => {
  const fetchedCount = ref(0)
  const items = ref([])
  const itemsByTypes = ref([])
  const params = ref()

  const fetch = async (prms) => {
    if (prms && !params.value) {
      params.value = prms
    }
    
    // console.log(params)

    try {
      const res = await api.get('/action/get-page', { params: params.value })

      if (res.status === 200) {
        // fetchedCount.value += params.value.limit
        // params.value.offset = params.value.offset += params.value.limit

        // items.value = [
        //   ...items.value,
        //   ...res.data.data
        // ]
        items.value = res.data.data

        setItemsByTypes()
        return groupItems()
        
        // if (fetchedCount.value < Number(res.data.count)) {
        //   fetch()
        // } else {
        //   setItemsByTypes()
        //   return groupItems()
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function groupItems() {
    let groupedObj = {};

    items.value.forEach((el) => {
      if (!groupedObj[el.medtype]) {
        groupedObj[el.medtype] = {}; // Создаем объект для данного `medtype`, если его нет
      }

      const processValue = typeof el.process === 'object' ? el.process.text : el.process;

      if (!groupedObj[el.medtype][processValue]) {
        groupedObj[el.medtype][processValue] = []; // Создаем массив для данного `process`, если его нет
      }

      groupedObj[el.medtype][processValue].push(el);
    });   

    return groupedObj
  }

  function setItemsByTypes() {
    const typeCounts = items.value.reduce((acc, item) => {
      acc[item.type.text] = (acc[item.type.text] || 0) + 1; // Увеличиваем счетчик для текущего типа
      return acc;
    }, {});

    itemsByTypes.value = typeCounts
  }

  const getCountByTypes = () => itemsByTypes.value

  return {
    fetch,
    getCountByTypes
  }
}

export default useActionsReport
