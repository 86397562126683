<template>
  <div class="card card-body h-100 mt-4 mb-4">
    <div class="mb-3">
      <div class="flex justify-content-between">
        <h4 class="card-title mb-4">Сводная таблица показателей {{ props.title }}</h4>
        <div>{{ yesterdayDate }}</div>
      </div>
      <NextTable
        class="zzz"
        :columns="columns"
        :table-data="tableData"
      >
        <template #name="{ value }">
          <span>{{ value }}</span>
        </template>
        <template v-for="col in columns" #[col.key]="{ value }" :key="col.key">
          <div class="text-center" :class="{ 'font-weight-bold': col.key === 'total' }">{{ value }}</div>
        </template>
      </NextTable>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue';
import api from '@/api'
import _ from 'lodash'
import moment from 'moment';
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import { computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
  },
  title: {
    type: String,
  },
})

const tableData = ref([])
const columns = ref([])

onMounted(() => {
  fetchData()
})

const yesterdayDate = computed(() => {
  return moment().subtract(1, 'days').format('DD.MM.YYYY')
})

const fetchData = async () => {
  try {
    const { data, status } = await api.get(`process/get-indicators-table?medtype_id=${props.id}`)

    if (status === 200 && data.length) {
      const preparedData = data.filter((item) => item.term !== 'Сводный')
      const groupedArray = _.groupBy(preparedData, 'name')
      const keys = Object.keys(groupedArray)
      const cols = {}

      const transformedParameters = keys
        .map((key) => {
          const parametersArrayByTerms = groupedArray[key]
          
          let resultParameter = {}
          let total = 0

          parametersArrayByTerms.map((parameterByTerm) => {
            resultParameter.name = parameterByTerm.name
            resultParameter[parameterByTerm.term] = parameterByTerm.value

            if (Number.isInteger(parseFloat(parameterByTerm.value))) {
              total += parseFloat(parameterByTerm.value)
            }

            cols[parameterByTerm.term] = {
              term: parameterByTerm.term,
            }
          })
          resultParameter.total = total

          return resultParameter
        })
        .filter((item) => item.total > 0)
      

      tableData.value = transformedParameters

      columns.value.push({
        key: 'name',
        title: 'Показатели',
        center: true,
        width: '600px',
      })
      columns.value.push({
        key: 'total',
        title: 'Всего',
        center: true
      })
      columns.value = [
        ...columns.value,
        ...Object.keys(cols).map((term) => ({
          title: term,
          key: term,
          center: true
        }))
      ]
    }
  } catch (error) {
    console.log('ERROR process/get-indicators-table?medtype_id=24', error);
  }
}
</script>
