<script setup>
import { onMounted, ref, watch } from 'vue'
import { NButton, NDatePicker, NSelect } from 'naive-ui'
import { storeToRefs } from 'pinia'

import { useOrganizationStore } from '@/store/organization'

import Audition from './Audition'
import Actions from './Actions.vue'
import Incidents from './Incidents.vue'
import Parameters from './Parameters.vue'
import { computed } from 'vue'

const { getDepartments } = useOrganizationStore()
const { departments, currentDepartment, currentSubdivision } = storeToRefs(useOrganizationStore())

const defaultRange = [
  new Date('2024-01-01T00:00:00').getTime(), // Начало 2024 года
  new Date('2024-12-31T23:59:59').getTime() // Конец 2024 года
]
const format = 'dd.MM.yyyy'

const range = ref(defaultRange)
const isResetDisabled = ref(true)

// для значения в селекте
const departmentValue = ref(null)
// для передачи через пропс всего объекта
const department = ref(null)

// аналогично
const subdivisionValue = ref(null)
const subdivision = ref(null)

onMounted(() => {
  getDepartments()
})

watch(
  () => range.value,
  (newRange) => {
    if (newRange[0] !== '1704056400000' || newRange[1] !== '1735678799000') {
      isResetDisabled.value = false
    }

    if (newRange[0] === '1704056400000' && newRange[1] === '1735678799000') {
      isResetDisabled.value = true
    }
  }
)

const showDepartmentsSelect = computed(() => Boolean(departments.value.length))
const departmentsOptions = computed(() => 
  departments.value.map((dep) => ({
    ...dep,
    label: dep.name,
    value: dep.id
  }))
)

const subdivisionsOptions = computed(() => {
  if (department.value?.organizationSubdivisions?.length === 0) {
    return []
  }

  return department.value?.organizationSubdivisions?.map((sub) => ({
    ...sub,
    label: sub.name,
    value: sub.id
  }))
})

const onUpdateDepartment = (_, option) => {
  department.value = option

  onClearDepartment()
}

const onClearDepartment = () => {
  subdivisionValue.value = null
  subdivision.value = null
}

const onUpdateSubdivision = (_, option) => {
  subdivision.value = option
}

const reset = () => {
  range.value = defaultRange
}
</script>

<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__left w-100">
        <div class="page-header__heading">Сводный отчёт</div>
        <div class="flex align-items-baseline">
          <NDatePicker v-model:value="range" type="daterange" :format="format" size="large" class="mr-3" />
          <NButton size="small" secondary :disabled="isResetDisabled" @click="reset">Сбросить</NButton>
        </div>
        <div v-if="showDepartmentsSelect" class="flex align-items-baseline">
          <NSelect 
            v-model:value="departmentValue"
            :options="departmentsOptions" 
            class="mr-3" 
            size="large" 
            placeholder="Выбрать подразделение"
            clearable
            :style="{ width: '300px' }"
            @update:value="onUpdateDepartment"
            @clear="onClearDepartment"
          />

          <NSelect 
            v-model:value="subdivisionValue"
            :options="subdivisionsOptions" 
            class="mr-3" 
            size="large" 
            placeholder="Выбрать отдел"
            clearable
            :style="{ width: '300px' }"
            @update:value="onUpdateSubdivision"
          />
        </div>
      </div>
    </div>

    <div class="content">
      <Audition :range="range" :department="department" :subdivision="subdivision" />
      <Actions :range="range" :department="department" :subdivision="subdivision" />
      <Incidents :range="range" :department="department" :subdivision="subdivision" />
      <Parameters class="mb-4" :department="department" :subdivision="subdivision" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.page-header {
  margin-bottom: 4rem;
}
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>