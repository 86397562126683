import { ref } from 'vue'

import api from '@/api'
import moment from 'moment'
import { isEqual } from 'lodash'

const useAuditionReport = () => {
  // const fetchedCount = ref(0)
  const items = ref([])
  const params = ref()

  const fetch = async (prms) => {
    
    if (!isEqual(prms, params.value)) {
      params.value = prms
    }

    try {
      const res = await api.get('/checklists/get-organization-checklists', { params: params.value })

      if (res.status === 200) {
        items.value = res.data.data
        return groupItems(params)
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function groupItems(params) {
    const {
      start_date,
      stop_date,
      department,
      subdivision,
    } = params.value

    let data = items.value || []

    if (department) {
      const membersIds = department.members.map((user) => Number(user.id))
      data = data.filter((el) => membersIds.includes(Number(el.assurance_user_id)) )
    }

    if (subdivision) {
      console.log('subdivision', subdivision)
      
      const membersIds = subdivision.members.map((user) => Number(user.id))
      data = data.filter((el) => membersIds.includes(Number(el.assurance_user_id)) )
    }

    // 1. Плановые проверки
    const d1 = data.filter((el) => el.status.id === 2 || el.status.id === 3).length // "Проверка завершена", "Подписан" 

    // 2. Проведено плановых проверок, за период
    const d2 = data.filter((el) => el.status.id === 2).length
    // 3. Количество проверок, запланированных за период
    // ???

    // 4. Процент выполнения плана проверок за период
    // ПЛАН(status=4) и ПРОВЕРКА ПРОВОДИТСЯ(status=1)
    // только ПЛАНОВАЯ ВНУТРЕННЯЯ и ПЛАН или ПЛАНОВАЯ ВНУТРЕННЯЯ и ПРОВОДИТСЯ ПРОВЕРКА
    // дата начала plan_date
    const d4 = data.filter((el) => el.assurance.id === 1 && [1,4].includes(el.status.id) && (start_date <= el.plan_date * 1000) && (el.plan_date * 1000 < stop_date) )
    
    const d4val = (100 - (d4.length / data.length * 100) || 0).toFixed(0)

    // 5. Кол-во плановых проверок, проведенных с нарушением сроков (больше 10 дней)
    const d5 = data.filter((el) => {
      // @TODO: учитывать рабочие дни
      const daysDiff = moment(el.finish_date).diff(moment(el.plan_date * 1000), 'days')
      if (daysDiff > 14) return el
      return
    })

    // 6. ... выявлены нарушения
    const d6 = data.filter((el) => el.requirements.some((req) => req.status_id === 3))

    // 7. Кол-во выявленных несоответствий (нарушений), в ходе плановых проверок
    const { data: { data: processes } } = await api.get('/process/formatted')
    
    const {
      requiredUnpassed,
      optionalUnpassed
    } = processes.reduce(
      (p, c) => {
        p.requiredUnpassed += c?.required_requirements_count?.unpassed || 0
        p.optionalUnpassed += c?.optional_requirements_count?.unpassed || 0
        return p
      },
      {
        requiredUnpassed: 0,
        optionalUnpassed: 0
      }
    )
    const d7 = requiredUnpassed + optionalUnpassed

    // 8. Внеплановые
    const d8 = data.filter((el) => el.assurance.id === 2).length

    return {
      d1,
      d2,
      d4val,
      d5,
      d6,
      d7,
      d8
    }

  }

  const fetchFormatedProcess = async () => {
    const { data: { data: medtypes } } = await api.get('/organization/get-organization-medtypes')

    const promises = medtypes.map(medtype => {
      return api.get('/process/formatted', {
        params: {
          medtype_id: medtype.id
        }
      })
    });

    const arrayOfResponseData = (await Promise.all(promises)).map((response, index) => ({
      medtype: medtypes[index],
      data: response.data.data,
    }))
    
    return arrayOfResponseData
  }

  return {
    fetch,
    fetchFormatedProcess
  }
}

export default useAuditionReport
