<script setup>
import { onMounted, ref, watch } from 'vue'
import { NDivider } from 'naive-ui';

import useIncidentsReport from '@/composables/useIncidentsReport'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import moment from 'moment';
import { computed } from 'vue';

const props = defineProps({
  range: {
    type: Array,
    default: () => ['', ''],
  },
  department: {
    type: Object,
  },
  subdivision: {
    type: Object,
  },
})

const params = {
  date_from: '2024-01-01',
  date_to: '2024-12-31',
};

const incidentsReport = useIncidentsReport()

const isLoadingIncidents = ref(true)
const incidentsReportData = ref(null)
const incidentsGroupedByProcess = ref(null)

onMounted(() => {
  fetchData()
})

const fetchData = async () => {
  isLoadingIncidents.value = true

  incidentsReportData.value = await incidentsReport.fetch(params)
  incidentsGroupedByProcess.value = incidentsReport.getGroupedByProcessText()

  isLoadingIncidents.value = false
}

const incidentsByDepartment = computed(() => {
  if (props.department) {
    const filteredByDepartment = incidentsReportData.value.filter((incident) => incident.department === props.department.label)

    if (props.subdivision) {
      return filteredByDepartment.map((department) => ({
        ...department,
        items: department.items.filter((departmentIncident) => departmentIncident.subdivision_id === props.subdivision.id)
      }))
    }
    
    return filteredByDepartment
  }

  return incidentsReportData.value
})

watch(
  () => props.range,
  (newRange) => {
    if (newRange[0] && newRange[1]) {
      params.date_from = moment(new Date(newRange[0]).getTime()).format('YYYY-MM-DD');
      params.date_to = moment(new Date(newRange[1]).getTime()).format('YYYY-MM-DD');
      fetchData();
    }
  },
  { immediate: false }
)
</script>

<template>
  <div v-if="isLoadingIncidents" class="flex">
    <SpinLoader style="display: inline-block;" class="w-8" />
    <p class="h5">Загрузка данных раздела Нежелательные события...</p>
  </div>

  <div v-if="incidentsReportData" class="mb-5">
    <NDivider><div style="font-size: 28px;">Отчёт по Нежелательным событиям</div></NDivider>

    <div class="mb-5" style="max-width: 1000px;">
      <div class="flex" style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc; ">
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 28%;">Подразделение </span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 18%;">Новые</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 18%;">В работе</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 18%;">Закрыто</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 18%;">Всего</span>
      </div>

      <div v-for="row in incidentsByDepartment" :key="row">
        <div class="flex" style="border-bottom: 1px solid #ccc;">
          <span class="px-2 py-1 cell" style="font-size: 16px; width: 28%; text-align: left;">{{ row.department }}</span>
          <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">{{ row.items.reduce((acc, el) => acc + el.countNew, 0) }}</span>
          <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">{{ row.items.reduce((acc, el) => acc + el.countInProcess, 0) }}</span>
          <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">{{ row.items.reduce((acc, el) => acc + el.countClosed, 0) }}</span>
          <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">{{ row.items.reduce((acc, el) => acc + el.count, 0) }}</span>
        </div>
      </div>
      <div class="flex" style="border-bottom: 1px solid #ccc; ">
        <span class="px-2 py-1 cell" style="font-size: 16px; width: 28%; text-align: left;">ИТОГО</span>
        <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">
          {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.countNew, 0), 0) }}
        </span>
        <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">
          {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.countInProcess, 0), 0) }}
        </span>
        <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">
          {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.countClosed, 0), 0) }}
        </span>
        <span class="px-2 py-1 cell" style="font-size: 16px; width: 18%;">
          {{ incidentsByDepartment.reduce((acc, incident) => acc + incident.items.reduce((acc, el) => acc + el.count, 0), 0) }}
        </span>
      </div>
    </div>

    <div v-for="row in incidentsByDepartment" :key="row" class="mb-4">
      <h6 class="mb-3">
        Подразделение "{{ row.department }}".
      </h6>
      <div class="flex gap-5 mb-3">
        <p class="mb-0">Новые: {{ row.items.reduce((acc, el) => acc + el.countNew, 0) }}</p>
        <p class="mb-0">В работе: {{ row.items.reduce((acc, el) => acc + el.countInProcess, 0) }}</p>
        <p class="mb-0">Закрыто: {{ row.items.reduce((acc, el) => acc + el.countClosed, 0) }}</p>
        <p class="mb-0"><span style="color: #1fc580;">Всего: {{ row.items.reduce((acc, el) => acc + el.count, 0) }}</span></p>
      </div>
      <div class="flex" style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc; ">
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 15%;">Направление</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 35%;">Раздел ВКК</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 40%;">Вид нежелательного события</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Количество</span>
      </div>

      <div v-for="item in row.items" :key="item" class="flex" style="border-bottom: 1px solid #ccc; border-right: 1px solid #ccc; ">
        <span class="cell" style="font-size: 16px; width: 15%; text-align: left">{{ item?.medType?.text }}</span>
        <span class="cell" style="font-size: 16px; width: 35%;">{{ item?.process?.text }}</span>
        <span class="cell" style="font-size: 16px; width: 40%;">{{ item.actionCategory?.name }}</span>
        <span class="cell" style="font-size: 16px; width: 10%;">{{ item.count }}</span>
      </div>
    </div>

    <div class="pt-4">
      <h5>Сводная таблица по нежелательным событиям</h5>

      <div class="flex" style="border-bottom: 1px solid #ccc; border-top: 1px solid #ccc; ">
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 5%;">№</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 35%;">Раздел</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 20%;">Направление</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Новые</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">В работе</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Закрыто</span>
        <span class="px-2 py-1 h-cell" style="font-size: 16px; width: 10%;">Всего</span>
      </div>
      <div v-for="[process, data] in Object.entries(incidentsGroupedByProcess)" :key="data" style="border-bottom: 1px solid #ccc; border-right: 1px solid #ccc; ">
        <div class="flex">
          <span class="cell text-left" style="font-size: 16px; width: 5%;">{{ data.processNumber }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 35%;">{{ process }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 20%;">{{ data.medType }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.new }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.inProcess }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.closed }}</span>
          <span class="cell text-left" style="font-size: 16px; width: 10%;">{{ data.total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
.h-cell {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  border-right: 1px solid #ccc;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}

.cell {
  font-size: 13px;
  text-align: center;
  border-right: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 4px 8px;

  &:first-child {
    border-left: 1px solid #ccc;
  }
}
</style>